import React, { Suspense } from "react";
import { BrowserRouter, Switch, Route as DomRouter } from "react-router-dom";
import { LoadingPage } from "./Layout/LoadingPage";
import { PrivateRoute, Route } from "./Definitions";

import RoutesConfig from "../Config/routes";

// Fixed pages
import Error404 from "../Pages/Errors/Error404";

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const Routes: React.FC = ({ children }) => {
  return (
    <BrowserRouter>
      {children}

      <Suspense fallback={<LoadingPage />}>
        <Switch>
          {/* OAUTH FLOW ROUTES */}
          {/* <Route exact path="/" page="OAuth/Login" /> */}
          {/* <Route exact path="/callback" page="OAuth/Callback" /> */}

          {/*  PUBLIC ROUTES */}
          <PrivateRoute exact path="/" page="Home" />

          {RoutesConfig?.public?.map((priv, privIndex) => (
            <Route exact {...priv} key={"pubroute_" + privIndex} />
          ))}

          {/* PRIVATE ROUTES */}
          {RoutesConfig?.private?.map((priv, privIndex) => (
            <PrivateRoute exact {...priv} key={"privroute_" + privIndex} />
          ))}

          {/* ERROR ROUTE */}
          <DomRouter component={Error404} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};
