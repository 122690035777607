import React, { useEffect, useState } from "react";
import OfflineIcon from "@material-ui/icons/NetworkLocked";
import { Typography } from "@material-ui/core";
import { useUI } from "../Hooks/useUI";

export const ConnectivityManager: React.FC = ({ children }) => {
  const [isOnline, setIsOnline] = useState(true);
  const [keepEditingOption, setKeepEditing] = useState(false);

  const setGlobalOfflineState = useUI(state => state.setOfflineState);

  useEffect(() => {
    window.addEventListener("load", () => {
      navigator.onLine ? setIsOnline(true) : setIsOnline(false);
      navigator.onLine
        ? setGlobalOfflineState(true)
        : setGlobalOfflineState(false);

      window.addEventListener("online", () => {
        setIsOnline(true);
        setGlobalOfflineState(true);
      });

      window.addEventListener("offline", () => {
        setIsOnline(false);
        setGlobalOfflineState(false);
      });
    });

    return () => {
      window.removeEventListener("online", () => {
        setIsOnline(true);
      });

      window.removeEventListener("offline", () => {
        setIsOnline(false);
      });
    };
  }, []);

  return (
    <>
      {children}

      {!isOnline && !keepEditingOption && (
        <div className={"offline-warning"}>
          <div style={{ display: "flex" }}>
            <OfflineIcon color={"error"} fontSize={"large"} />
            <Typography
              style={{ marginLeft: 15 }}
              color={"error"}
              variant={"h5"}
            >
              Sem acesso a internet no momento
            </Typography>
          </div>
          <div style={{ maxWidth: 400, textAlign: "center", marginTop: 20 }}>
            <Typography variant={"caption"}>
              Você pode{" "}
              <Typography
                className="span-link"
                variant={"caption"}
                color={"primary"}
                onClick={() => {
                  setKeepEditing(true);
                }}
              >
                continuar trabalhando
              </Typography>
              , mas não será possível salvar suas alterações até que uma conexão
              com a internet seja retomada.
            </Typography>
          </div>
        </div>
      )}
    </>
  );
};
