import React from "react";
import { PersistGate } from "zustand-persist";
import { usePreferences } from "./Hooks";
import { ToastProvider } from "react-toast-notifications";

export const Bootstrap: React.FC = ({ children }) => {
  usePreferences();

  return (
    <PersistGate
      onBeforeLift={() => {
        console.log("onBeforeLift");
      }}
      //   loading={<Loading />}
    >
      <ToastProvider
        autoDismiss
        autoDismissTimeout={6000}
        placement="bottom-right"
      >
        <>{children}</>
      </ToastProvider>
    </PersistGate>
  );
};
