export default {
  private: [
    { path: "/perfil", page: "Profile" },
    { path: "/cfops", page: "CFOPs" },
    { path: "/tipos-pagamento", page: "TiposPagamento" },
    { path: "/estados", page: "Estados" },
    { path: "/cidades", page: "Cidades" },
    { path: "/pessoas", page: "Pessoas" }
  ],
  public: []
};
