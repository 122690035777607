import { CircularProgress } from "@material-ui/core";
import React from "react";

export const LoadingPage: React.FC = ({ children }) => {
  return (
    <main
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <CircularProgress size={50} />
    </main>
  );
};
