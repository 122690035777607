import { useEffect } from "react";
import { usePreferences } from "./Hooks";
import { Aside, Footer, Header, Nav } from "./Layout";
import { Routes } from "./Routes";
import { Theming } from "./Theming";
import LoginScreen from "./Auth/LoginScreen";
import classnames from "classnames";
import { Mymba, UsuariosController } from "mymba";
import { Overlays } from "./Overlays";
import { Bootstrap } from "./Bootstrap";
import { useUI } from "./Hooks/useUI";
import { ConnectivityManager } from "./ConnectivityManager";

function App() {
  const accessToken = usePreferences(state => state.accessToken);
  const setUser = usePreferences(state => state.setUser);
  const toggleLoading = useUI(state => state.toggleLoading);
  const gridMode = useUI(state => state.gridMode);
  // const gridMode = "default-grid";

  useEffect(() => {
    Mymba.Initialize({
      baseURL: String(process.env.REACT_APP_API_BASE_URL),
      debug: String(process.env.REACT_APP_API_DEBUG) === "true"
    });
  }, []);

  useEffect(() => {
    toggleLoading(true);

    if (accessToken) {
      Mymba.getInstance().getApi().setAccessToken(accessToken);

      const usu = new UsuariosController();
      usu
        .Me()
        .then(result => {
          setUser(result.data);
        })
        .catch(e => {})
        .then(() => {
          toggleLoading(false);
        });
    } else {
      setUser(undefined);
      toggleLoading(false);
    }
  }, [accessToken]);

  return (
    <div
      className={classnames({
        admin: true,
        light: true,
        "default-grid": gridMode !== "default-grid" ? false : !!accessToken,
        "unauthenticated-grid": !accessToken,
        [gridMode]: true
      })}
    >
      <ConnectivityManager>
        <Bootstrap>
          <Theming>
            {!accessToken ? (
              <LoginScreen />
            ) : (
              <Routes>
                <Header />
                <Nav />
                <Aside />
                <Footer />
              </Routes>
            )}

            <Overlays />
          </Theming>
        </Bootstrap>
      </ConnectivityManager>
    </div>
  );
}

export default App;
