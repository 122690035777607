export default ({
  primaryColor = "#1f6db5",
  secondaryColor = "#7D7D7D",
  size = 400,
  ...props
}) => {
  return (
    <svg
      width={size}
      height={size * 0.3636363636363636}
      viewBox="0 0 825 300"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0)">
        <path
          d="M380.485 172.976C380.485 180.945 378.459 188.035 374.414 194.246C370.365 200.398 364.526 205.203 356.901 208.66C349.275 212.058 340.329 213.758 330.062 213.758C321.497 213.758 314.311 213.172 308.503 212C302.695 210.77 296.652 208.66 290.376 205.672V174.734C297.004 178.133 303.898 180.799 311.055 182.732C318.212 184.608 324.783 185.545 330.766 185.545C335.929 185.545 339.712 184.666 342.119 182.908C344.522 181.092 345.727 178.777 345.727 175.965C345.727 174.207 345.227 172.684 344.229 171.394C343.292 170.047 341.738 168.699 339.568 167.352C337.453 166.004 331.763 163.25 322.495 159.09C314.106 155.281 307.799 151.59 303.575 148.016C299.41 144.442 296.301 140.34 294.247 135.711C292.253 131.082 291.255 125.604 291.255 119.275C291.255 107.44 295.567 98.2108 304.191 91.59C312.815 84.9688 324.665 81.6584 339.744 81.6584C353.06 81.6584 366.64 84.7344 380.485 90.8868L369.836 117.693C357.81 112.186 347.425 109.432 338.687 109.432C334.169 109.432 330.884 110.223 328.83 111.805C326.777 113.387 325.75 115.35 325.75 117.693C325.75 120.213 327.041 122.469 329.622 124.461C332.262 126.453 339.359 130.086 350.917 135.359C362.007 140.34 369.692 145.701 373.973 151.443C378.315 157.127 380.485 164.305 380.485 172.976Z"
          fill={primaryColor}
        />
        <path
          d="M516.426 212V157.859C516.426 151.121 515.489 146.082 513.611 142.742C511.792 139.344 508.889 137.644 504.9 137.644C499.618 137.644 495.749 139.9 493.282 144.412C490.819 148.924 489.589 155.984 489.589 165.594V212H455.092V157.859C455.092 151.121 454.215 146.082 452.452 142.742C450.694 139.344 447.847 137.644 443.918 137.644C438.52 137.644 434.591 140.047 432.124 144.852C429.661 149.656 428.431 157.508 428.431 168.406V212H394.022V112.42H419.981L424.294 124.725H426.317C428.956 120.154 432.773 116.668 437.759 114.266C442.801 111.863 448.524 110.662 454.915 110.662C469.289 110.662 479.381 115.027 485.188 123.758H487.915C490.731 119.656 494.632 116.463 499.618 114.178C504.664 111.834 510.21 110.662 516.25 110.662C528.044 110.662 536.755 113.68 542.385 119.715C548.016 125.75 550.836 134.92 550.836 147.225V212H516.426Z"
          fill={primaryColor}
        />
        <path
          d="M634.946 212L628.346 198.816H627.641C623.007 204.558 618.285 208.484 613.476 210.594C608.666 212.703 602.446 213.758 594.821 213.758C585.434 213.758 578.041 210.945 572.642 205.32C567.248 199.695 564.548 191.785 564.548 181.59C564.548 170.984 568.245 163.104 575.638 157.947C583.027 152.732 593.736 149.803 607.757 149.158L624.385 148.631V147.225C624.385 139.022 620.34 134.92 612.242 134.92C604.969 134.92 595.73 137.381 584.525 142.303L574.58 119.627C586.195 113.65 600.86 110.662 618.578 110.662C631.369 110.662 641.253 113.826 648.234 120.154C655.274 126.482 658.795 135.33 658.795 146.697V212H634.946ZM609.427 189.5C613.592 189.5 617.14 188.182 620.076 185.545C623.067 182.908 624.561 179.48 624.561 175.262V167.527L616.643 167.879C605.322 168.289 599.659 172.449 599.659 180.359C599.659 186.453 602.915 189.5 609.427 189.5Z"
          fill={primaryColor}
        />
        <path
          d="M738.067 110.662C741.058 110.662 743.902 110.896 746.601 111.365L748.535 111.717L745.456 144.148C742.64 143.445 738.739 143.094 733.753 143.094C726.244 143.094 720.79 144.822 717.386 148.279C713.985 151.678 712.283 156.6 712.283 163.045V212H677.874V112.42H703.481L708.851 128.328H710.521C713.397 123.055 717.386 118.807 722.492 115.584C727.654 112.303 732.844 110.662 738.067 110.662Z"
          fill={primaryColor}
        />
        <path
          d="M807.042 186.6C811.676 186.6 817.219 185.457 823.674 183.172V208.221C819.041 210.213 814.611 211.619 810.386 212.44C806.221 213.318 801.323 213.758 795.689 213.758C784.135 213.758 775.804 210.945 770.698 205.32C765.596 199.637 763.045 190.936 763.045 179.217V138.172H750.986V124.197L766.301 113.387L775.188 92.4688H797.715V112.42H822.177V138.172H797.715V176.932C797.715 183.377 800.823 186.6 807.042 186.6Z"
          fill={primaryColor}
        />
        <path
          d="M34.7589 161.242V208H0V79.504H42.1507C77.1151 79.504 94.5971 92.16 94.5971 117.473C94.5971 132.356 87.3226 143.869 72.7738 152.014L110.26 208H70.8379L43.5588 161.242H34.7589ZM34.7589 135.139H41.2708C53.4143 135.139 59.4861 129.777 59.4861 119.055C59.4861 110.207 53.5317 105.783 41.6229 105.783H34.7589V135.139Z"
          fill={primaryColor}
        />
        <path
          d="M211.004 120.373C211.004 134.728 206.751 145.832 198.245 153.684C189.797 161.476 177.77 165.373 162.166 165.373H152.398V208H117.639V79.504H162.166C178.416 79.504 190.618 83.0488 198.773 90.1388C206.927 97.2284 211.004 107.307 211.004 120.373ZM152.398 137.072H158.734C163.955 137.072 168.091 135.608 171.141 132.678C174.25 129.748 175.805 125.705 175.805 120.549C175.805 111.877 170.995 107.541 161.374 107.541H152.398V137.072Z"
          fill={primaryColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M138.281 268C209.059 268 266.436 208.006 266.436 134C266.436 59.994 209.059 0 138.281 0C113.377 0 90.1317 7.4276 70.4618 20.2796C86.146 12.408 103.713 8 122.261 8C188.616 8 242.407 64.412 242.407 134C242.407 203.588 188.616 260 122.261 260C103.713 260 86.146 255.592 70.4618 247.72C90.1317 260.572 113.377 268 138.281 268Z"
          fill={primaryColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M219.786 86.5961C203.248 61.6549 178.056 42.2597 146.996 33.4125C96.5235 19.0357 44.5093 36.4841 12.4376 73.8217C34.7983 38.1549 75.5881 15.6169 120.569 18.6373C165.084 21.6261 202.178 48.8741 219.786 86.5961Z"
          fill={secondaryColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M264 207.238C235.955 252.585 186.109 283.18 128.788 284.197C84.3672 284.984 43.8004 267.859 13.9979 239.483C40.2041 276.872 83.9515 300.994 133.077 300.123C193.419 299.053 244.311 260.587 264 207.238Z"
          fill={secondaryColor}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="825" height="300" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
