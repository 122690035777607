import React, { useEffect } from "react";
import { Route as DomRouter, RouteProps } from "react-router-dom";
import { useUI } from "../Hooks/useUI";

export type MRouteProps = RouteProps & {
  page: string;
};

export const Route: React.FC<MRouteProps> = ({ page, ...props }) => {
  const Page = React.memo(React.lazy(() => import(`../../Pages/${page}`)));
  const requestGridMode = useUI(state => state.requestGridMode);

  useEffect(() => {
    requestGridMode("default-grid");
  }, []);

  return <DomRouter {...props} component={() => <Page />} />;
};
