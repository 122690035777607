import { Divider, makeStyles, Paper } from "@material-ui/core";
import React from "react";
import AppConfig from "../../Config/app.json";
import { usePreferences } from "../Hooks";

const useStyles = makeStyles(theme => ({
  footer: {
    flexGrow: 1,
    padding: 5,
    zIndex: theme.zIndex.drawer + 1
  }
}));

export const Footer: React.FC = React.memo(
  () => {
    const classes = useStyles();
    const user = usePreferences(state => state.user);

    return (
      <footer>
        <Paper
          elevation={1}
          square
          variant={"outlined"}
          className={classes.footer}
        >
          <div className="group">
            <section>© {new Date().getFullYear()} RP Smart</section>
            {/* <Divider /> */}
            <section>{AppConfig.productName}</section>
            {/* <Divider /> */}
            <section>{AppConfig.version}</section>
            {/* <Divider /> */}
            <section>{user?.Empresa?.nmRazaoSocial}</section>
          </div>

          {/* <div className="group">
        <section></section>
      </div> */}
        </Paper>
      </footer>
    );
  },
  () => false
);
