import {
  ListItemText,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  makeStyles,
  Toolbar,
  createStyles,
  Theme,
  Divider,
  ListSubheader
} from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router";

import NavConfig, { MenuItemProps, NavigateProps } from "../../Config/nav";

type MenuItemParsedProps = {
  menu: NavigateProps;
  item: MenuItemProps;
  history: any;
  index: number;
  menuIndex: number;
};

const navigate = ({ item, history }: any) => {
  console.log(item);
  history.push(`${item.path}`);
};

const MenuItemParsed: React.FC<MenuItemParsedProps> = React.memo(
  ({ menu, item, history, index }) => {
    return (
      <ListItem
        button
        key={item.text + "_" + index}
        onClick={() => {
          navigate({ item, history });
        }}
      >
        <ListItemIcon>{item.icon && <item.icon />}</ListItemIcon>
        <ListItemText primary={item.text} />
      </ListItem>
    );
    // let childrens = null;
    // realIndex++;

    // if (menu?.children?.length > 0) {
    //   childrens = menu?.children.map((m: any) => (
    //     <MenuItemParsed menu={m} index={"sub_" + realIndex} history={history} />
    //   ));
    // }

    // return (
    //   <MenuItem
    //     onClick={() => {
    //       navigate({ menu, history });
    //     }}
    //     {...menu}
    //     key={"menu_item_" + index}
    //     children={childrens}
    //   />
    // );
  },
  (prev, next) => prev.item !== next.item
);

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex"
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0
    },
    drawerPaper: {
      width: drawerWidth
    },
    drawerContainer: {
      overflow: "auto"
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3)
    }
  })
);

export const Nav: React.FC = () => {
  const history = useHistory();
  const classes = useStyles();
  // const theme = useTheme();

  // const [open, setOpen] = React.useState(true);

  // const handleDrawerOpen = () => {
  //   setOpen(true);
  // };

  // const handleDrawerClose = () => {
  //   setOpen(false);
  // };

  return (
    <nav>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <Toolbar />
        <div className={classes.drawerContainer}>
          {/* <List>
            {["Inbox", "Starred", "Send email", "Drafts"].map((text, index) => (
              <ListItem button key={text}>
                <ListItemIcon>
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItem>
            ))}
          </List>
          <Divider /> */}
          {NavConfig?.menus?.map((menu: NavigateProps, menuIndex: number) => {
            return (
              <>
                <List key={"menuItem_" + menuIndex}>
                  {menu.title && <ListSubheader>{menu.title}</ListSubheader>}
                  {menu.items?.map((item: MenuItemProps, itemIndex: number) => {
                    return (
                      <MenuItemParsed
                        key={"menuItem_" + menuIndex + "_" + itemIndex}
                        menu={menu}
                        item={item}
                        history={history}
                        index={itemIndex}
                        menuIndex={menuIndex}
                      />
                    );
                  })}
                </List>
                {NavConfig?.menus?.length > 1 && <Divider />}
              </>
            );
          })}
          {/* 
            {NavConfig?.menu?.map((menu: any, index: number) => {
              if (menu.type === "heading") {
                return <MenuItem disabled {...menu} />;
              }

              return (
              );
            })} */}
        </div>
      </Drawer>

      {/* <Menu className={Classes.ELEVATION_1}>
        {NavConfig?.menu?.map((menu: any, index: number) => {
          if (menu.type === "heading") {
            return <MenuItem disabled {...menu} />;
          }

          return <MenuItemParsed menu={menu} history={history} index={index} />;
        })}
      </Menu> */}
    </nav>
  );
};
