import { AppBar, ThemeProvider, Toolbar, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { Screen } from "./Screen";
import AppConfig from "../../Config/app.json";
import { SubactionsTheme } from "../../App/Themes";

type MainProps = {
  subactions?: React.ReactNode;
  title?: string;
};

export const Main: React.FC<MainProps> = ({ children, title, subactions }) => {
  useEffect(() => {
    if (title) {
      document.title = title + " | " + AppConfig.productName;
    }
  }, [title]);

  return (
    <>
      {subactions && (
        <>
          <ThemeProvider theme={SubactionsTheme}>
            <AppBar position="static" id={"subactions"} variant={"outlined"}>
              <Toolbar variant="dense">{subactions}</Toolbar>
            </AppBar>
          </ThemeProvider>
        </>
      )}
      <main>
        <Screen>{children}</Screen>
      </main>
    </>
  );
};
