import { createMuiTheme } from "@material-ui/core";

export const DefaultTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#1f6db5"
    },
    secondary: {
      main: "#179E60"
    }
  }
});
