import { createMuiTheme } from "@material-ui/core";
import typography from "./typography";

export const SubactionsTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#FFFFFF"
    },
    secondary: {
      main: "#1f6db5"
    }
  },
  typography: {
    ...typography,
    button: {
      fontWeight: 600
    }
  }
});
