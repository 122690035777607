import React, { useCallback, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import RPSmart from "../../Assets/RPSmart";
import { isEmpty, trim } from "lodash";
import { useUI } from "../Hooks/useUI";
import { Mymba } from "mymba";
import { useToasts } from "react-toast-notifications";
import { usePreferences } from "../Hooks";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"© "}
      <Link color="inherit" href="https://rpsmart.com.br/">
        RP Smart
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

const LoginScreen = () => {
  const classes = useStyles();
  const toast = useToasts();

  const { toggleLoading, requestGridMode } = useUI();
  const { setAccessToken } = usePreferences();

  const [dsLogin, setDSLogin] = useState("");
  const [dsSenha, setDSSenha] = useState("");

  const signin = useCallback(async () => {
    toggleLoading(true);

    try {
      const loginResult = await Mymba.getInstance()
        .getApi()
        .send("POST", "v1", "auth", {
          dsLogin,
          dsSenha
        });

      setAccessToken("213213213213213213");

      console.log(loginResult);
    } catch (e) {
      console.log(e);
      toast.addToast(e.message, {
        appearance: "error"
      });
    }

    toggleLoading(false);
  }, [dsLogin, dsSenha]);

  useEffect(() => {
    requestGridMode("unauthenticated-grid");
  });

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <RPSmart size={300} style={{ marginBottom: 30 }} />

        <Typography component="h1" variant="h5">
          Entrar
        </Typography>
        <form className={classes.form} noValidate onSubmit={signin}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="dsLogin"
            label="Usuário"
            name="dsLogin"
            autoComplete="username"
            autoFocus
            onChange={tgt => {
              setDSLogin(tgt.target.value);
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="dsSenha"
            label="Senha"
            type="password"
            id="dsSenha"
            autoComplete="current-password"
            onChange={tgt => {
              setDSSenha(tgt.target.value);
            }}
          />
          {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Lembrar "
          /> */}
          <Button
            fullWidth
            variant="contained"
            color="primary"
            disabled={isEmpty(trim(dsLogin)) || isEmpty(trim(dsSenha))}
            className={classes.submit}
            onClick={signin}
          >
            Entrar
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Esqueci minha senha
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" variant="body2">
                Criar uma conta Mymba
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default LoginScreen;
