import React from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";
import { DefaultTheme } from "./Themes";

export const Theming: React.FC = ({ children }) => {
  return (
    <ThemeProvider theme={DefaultTheme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};
