import { CircularProgress } from "@material-ui/core";
import React from "react";
import { useUI } from "./Hooks/useUI";

export const Overlays: React.FC = ({ children }) => {
  const loadingOverlay = useUI(state => state.loadingOverlay);

  return (
    <>
      {loadingOverlay?.showing && (
        <div className="loading-overlay">
          <CircularProgress size={80} />
        </div>
      )}
    </>
  );
};
