import Class from "@material-ui/icons/Class";
import AcUnit from "@material-ui/icons/AcUnit";
import Accessible from "@material-ui/icons/Accessible";

export type NavigateProps = {
  title?: string;
  items: MenuItemProps[];
};

export type MenuItemProps = {
  icon: any;
  text: string;
  path: string;
  childrens?: MenuItemProps[];
};

const MENU: { menus: NavigateProps[] } = {
  menus: [
    {
      // title: "Teste 1",
      items: [
        {
          icon: Class,
          text: "CFOP",
          path: "/cfops"
        },
        {
          icon: Class,
          text: "Tipos Pagamento",
          path: "/tipos-pagamento"
        },
        {
          icon: AcUnit,
          text: "Estados",
          path: "/estados"
        },
        {
          icon: Accessible,
          text: "Cidades",
          path: "/cidades"
        },
        {
          icon: Accessible,
          text: "Pessoas",
          path: "/pessoas"
        },
      ]
    }
  ]
};

export default MENU;
