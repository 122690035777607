import create from "zustand";
import { configurePersist } from "zustand-persist";
import { Usuario } from "mymba";

const { persist } = configurePersist({
  storage: localStorage,
  rootKey: "nw-admin"
});

type PreferencesProps = {
  user?: Usuario;
  accessToken?: string;

  setUser: (user?: Usuario) => void;
  setAccessToken: (accessToken?: string) => void;
};

export const usePreferences = create<PreferencesProps>(
  persist(
    {
      key: "local-prefs"
    },
    set => ({
      user: undefined,
      accessToken: undefined,
      setUser: (user?: any) => set(state => ({ ...state, user: user })),
      setAccessToken: (accessToken?: string) =>
        set(state => ({ ...state, accessToken: accessToken }))
    })
  )
);
