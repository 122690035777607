import create from "zustand";

type GridModeOption =
  | "default-grid"
  | "subactions-grid"
  | "unauthenticated-grid";

type UIProps = {
  loadingOverlay: { showing: boolean; text: string };
  offlineState: boolean;
  gridMode: GridModeOption;
  toggleLoading: (show: boolean) => void;
  setOfflineState: (stateIs: boolean) => void;
  requestGridMode: (grid: GridModeOption) => void;
};

export const useUI = create<UIProps>(set => ({
  loadingOverlay: {
    showing: false,
    text: "Carregando..."
  },

  gridMode: "default-grid",
  offlineState: false,

  toggleLoading: (show: boolean = true) =>
    set(state => ({
      ...state,
      loadingOverlay: { ...state.loadingOverlay, showing: show }
    })),

  setOfflineState: (stateIs: boolean = false) =>
    set(state => ({
      ...state,
      offlineState: stateIs
    })),

  requestGridMode: (grid: GridModeOption = "default-grid") =>
    set(state => ({
      ...state,
      gridMode: grid
    }))
}));
