import React, { useEffect } from "react";
import { RouteProps, Route as DomRoute, useHistory } from "react-router-dom";
import { usePreferences } from "../Hooks";
import { Route } from "./Route";

import Error401 from "../../Pages/Errors/Error401";
import { useUI } from "../Hooks/useUI";

export type PrivateRouteProps = RouteProps & {
  permissions?: String[];
  page: string;
};

export const PrivateRoute: React.FC<PrivateRouteProps> = props => {
  const requestGridMode = useUI(state => state.requestGridMode);
  const accessToken = usePreferences(state => state.accessToken);
  const history = useHistory();

  useEffect(() => {
    if (!accessToken) {
      history.replace("/");
    }

    requestGridMode("default-grid");
  }, []);

  if (!accessToken) {
    return <DomRoute {...props} component={Error401} />;
  }

  return <Route {...props} />;
};
