import { createMuiTheme } from "@material-ui/core";

export const DefaultDialogFooterTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#d22424"
    },
    secondary: {
      main: "#179E60"
    }
  }
});
